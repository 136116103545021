<script>
import { CountTo } from "vue3-count-to";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";

import BuyRequestServices from "../../services/BuyRequestServices";

import SMSSender from "./parts/sms-sender";
import { ASSETS_BASE_URL } from "@/helpers/constants";

import { VueEditor } from "vue3-editor";

export default {
  page: {
    title: "Détails de la souscription",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      ASSETS_BASE_URL: ASSETS_BASE_URL,
      isCreateTxModalOpen: false,
      isDefinePriceModalOpen: false,
      fields_notes: "",
      fields_price: 0,
      fields: {
        amount: "",
        reason: "",
        channel: "cash",
        paid_at: "",
      },
      title: "Informations sur la requête d'achat",
      items: [
        {
          text: "Requêtes d'achat",
          href: "/buy-requests",
        },
        {
          text: "Détails de la souscrition",
          active: true,
        },
      ],
      date3: null,
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      date: null,
      allTransactions: [],
      searchQuery: null,
      page: 1,
      perPage: 8,
      pages: [],
      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      currentSubscription: { steps: [] },
    };
  },
  components: {
    CountTo,
    Layout,
    PageHeader,
    lottie: Lottie,
    // Multiselect,
    SMSSender,
    flatPickr,
    VueEditor,
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.allTransactions);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return data.reason.toLowerCase().includes(search);
        });
      } else if (this.filterdate !== null) {
        console.log(this.filterdate);
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.dueDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.dueDate.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          console.log(this.filtervalue);
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    allTransactions() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  beforeMount() {
    this.getCurrentSubscription();
  },

  methods: {
    openCreateTxModal() {
      this.isCreateTxModalOpen = true;
    },
    openDefinePriceModal() {
      this.isDefinePriceModalOpen = true;
    },
    handleSaveNotes() {
      this.$startLoading();
      BuyRequestServices.saveNotes(this.$route.params.id, {
        notes: this.fields_notes,
      })
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Notes enregistrées avec succès.");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de l'enregistrement de vos notes.");
        })
        .finally(() => {
          this.getCurrentSubscription();
          // this.$stopLoading();
        });
    },
    handleDefinePrice() {
      this.$startLoading();
      BuyRequestServices.definePrice(this.$route.params.id, {
        price: this.fields_price,
        products: [],
      })
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Prix enregistré avec succès.");
            this.isDefinePriceModalOpen = false
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la définition du prix.");
        })
        .finally(() => {
          this.getCurrentSubscription();
          // this.$stopLoading();
        });
    },
    handleCreateTransaction() {
      this.$startLoading();
      BuyRequestServices.createTransaction(this.$route.params.id, this.fields)
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Votre transaction a été créée avec succès.");

            this.fields = {
              reason: "",
              channel: "cash",
              amount: null,
              paid_at: null,
            };
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la création de votre transaction.");
        })
        .finally(() => {
          this.getCurrentSubscription();
          // this.$stopLoading();
        });
    },
    getCurrentSubscription() {
      BuyRequestServices.getById(this.$route.params.id)
        .then((data) => {
          this.currentSubscription = data.request;
          this.fields_notes = data.request.notes;
          this.allTransactions = [];
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          data.request.payments.forEach((row) => {
            var dd = new Date(row.created_at);
            row.dueDate =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();

            // row.image_src = `@/assets/images/products/img-8.png`;
            // row.image_src = 'https://api-node.themesbrand.website/fileupload/users_bucket/' + row.img;
            this.allTransactions.push(row);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    },
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allTransactions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(allTransactions) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return allTransactions.slice(from, to);
    },
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal
      v-model="isCreateTxModalOpen"
      hide-footer
      title="Ajout d'une transaction"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="amount-field" class="form-label"> Montant </label>
          <input
            type="text"
            id="amount-field"
            class="form-control"
            placeholder="Entrez le montant"
            required
            v-model="fields.amount"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label"> Description </label>
          <input
            type="text"
            id="email"
            class="form-control"
            placeholder="Description du paiement"
            required
            v-model="fields.reason"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label">
            Canal de paiement
          </label>
          <select class="form-select" v-model="fields.channel">
            <option value="cash">Paiement en espèce</option>
            <option value="momo">MTN Mobile Money</option>
            <option value="om">Orange Money</option>
            <option value="card">Carte</option>
            <option value="bank">Virement bancaire</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="paid_at" class="form-label">Payé le</label>
          <input
            type="datetime-local"
            id="paid_at"
            class="form-control"
            placeholder="Entrez la date de paiement"
            required
            v-model="fields.paid_at"
          />
        </div>
        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleCreateTransaction"
            >
              Enregistrer la transaction
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="isDefinePriceModalOpen"
      hide-footer
      title="Définir le prix de la requête"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="amount-field" class="form-label"> Montant </label>
          <input
            type="number"
            id="amount-field"
            class="form-control"
            placeholder="Entrez le montant"
            required
            v-model="fields_price"
          />
        </div>

        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="handleDefinePrice"
            >
              Enregistrer le prix
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <router-link
      :to="{
        name: 'buy-requests.list',
      }"
      class="btn btn-light mb-3"
    >
      &lt;-- Retour
    </router-link>

    <div class="row">
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Total à payer</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="currentSubscription.total_price"
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="ri-ticket-2-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Montant versé</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="currentSubscription.current_balance"
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Reste à verser</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="
                      currentSubscription.total_price -
                      currentSubscription.current_balance
                    "
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">
                Transactions pour <b>#{{ currentSubscription.code }}</b>
              </h5>
              <div class="flex-shrink-0">
                <button
                  class="btn btn-danger add-btn"
                  @click="openCreateTxModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Ajouter un
                  paiement
                </button>
                <button
                  v-if="!currentSubscription.total_price"
                  class="ms-2 btn btn-info add-btn"
                  @click="openDefinePriceModal"
                >
                  <i class="ri-save-line align-bottom me-1"></i> Définir le prix
                </button>
              </div>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-12">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search bg-light border-light"
                      placeholder="Search for tasks or something..."
                      v-model="filtersearchQuery1"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-4 col-sm-4">
                  <flat-pickr
                    v-model="filterdate1"
                    placeholder="Selectionner la date"
                    :config="rangeDateconfig"
                    class="form-control"
                  ></flat-pickr>
                </div>
                <!--end col-->

                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="SearchData"
                  >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                    Filters
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <!--end card-body-->
          <div class="card-body">
            <div class="table-responsive table-card mb-4">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light text-muted">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Raison</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Solde précédent</th>
                    <th scope="col">Nouveau solde</th>
                    <th scope="col">Payé le</th>
                  </tr>
                </thead>
                <tbody class="list form-check-all">
                  <tr v-for="(item, index) of resultQuery" :key="index">
                    <td>#{{ index + 1 }}</td>
                    <td>
                      {{ item.reason }}
                    </td>
                    <td>{{ $formatPrice(item.amount) }}</td>
                    <td>{{ $formatPrice(item.balance_before) }}</td>
                    <td>{{ $formatPrice(item.balance_after) }}</td>
                    <td>{{ item.dueDate }}</td>
                  </tr>
                </tbody>
              </table>

              <!--end table-->
              <div class="noresult" style="display: none">
                <div class="text-center">
                  <lottie
                    colors="primary:#121331,secondary:#08a88a"
                    :options="defaultOptions"
                    :height="75"
                    :width="75"
                  />
                  <h5 class="mt-2">Sorry! No Result Found</h5>
                  <p class="text-muted mb-0">...</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <div class="pagination-wrap hstack gap-2">
                <a
                  class="page-item pagination-prev disabled"
                  href="#"
                  v-if="page != 1"
                  @click="page--"
                >
                  Précédent
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                    :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                    v-for="(pageNumber, index) in pages.slice(
                      page - 1,
                      page + 5
                    )"
                    :key="index"
                    @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                  class="page-item pagination-next"
                  href="#"
                  @click="page++"
                  v-if="page < pages.length"
                >
                  Suivant
                </a>
              </div>
            </div>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">
            Informations sur l'utilisateur
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nom complet</h5>
                <p class="card-text">
                  {{ currentSubscription.contact_name }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Email</h5>
                <p class="card-text">
                  {{ currentSubscription.contact_email }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Téléphone</h5>
                <p class="card-text">{{ currentSubscription.contact_phone }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">E-mail</h5>
                <p class="card-text">{{ currentSubscription.contact_email }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ville de réception</h5>
                <p class="card-text">{{ currentSubscription.contact_city }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Adresse</h5>
                <p class="card-text">
                  {{ currentSubscription.contact_address }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">
            Informations sur le produit recherché
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="">
          <h5>Les images</h5>
          <a
            v-for="image of currentSubscription.images"
            :key="image.id"
            :href="ASSETS_BASE_URL + '' + image.file.url"
            target="_blank"
            rel="noopener noreferrer"
            class="mb-3"
            :title="image.file.name"
          >
            <img
              :src="ASSETS_BASE_URL + '' + image.file.url"
              style="height: 100px"
            />
          </a>
        </div>

        <div class="mt-3">
          <h5>Les liens</h5>
          <a
            v-for="link of currentSubscription.links"
            :href="link.link"
            :key="link.id"
            target="_blank"
          >
            {{ link.link }}
          </a>
        </div>
      </div>
    </div>

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">Envoyer un SMS manuel</h5>
        </div>
      </div>
      <div class="card-body">
        <SMSSender
          :currentSubscription="currentSubscription"
          @reloadDatas="getCurrentSubscription"
        />
      </div>
    </div>

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">Notes de la requête</h5>
        </div>
      </div>
      <div class="card-body">
        <VueEditor v-model.trim="fields_notes" required></VueEditor>
        <button
          type="button"
          class="btn btn-primary mt-3"
          @click="handleSaveNotes"
        >
          Enregistrer
        </button>
      </div>
    </div>
  </Layout>
</template>
